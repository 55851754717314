import { A11yModule } from "@angular/cdk/a11y";
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewEncapsulation,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";

export interface ConfirmDialogData {
  title: string;
  message: string;
  confirmLabel: string;
  cancelLabel: string;
}

const CONFIRM_DIALOG_DEFAULT_DATA: ConfirmDialogData = {
  title: "Confirmation",
  message: "Confirm this action ?",
  confirmLabel: "Yes",
  cancelLabel: "No",
};

@Component({
  standalone: true,
  selector: "app-confirm-dialog",
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      app-confirm-dialog {
        .message {
          font-size: 0.85rem;
        }

        .mat-mdc-dialog-content {
          border-top: 1px solid #dadada;
        }

        .confirm-button {
          border-radius: 1rem !important;
          height: 40px !important;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [A11yModule, MatButtonModule, MatIconModule, MatDialogModule],
  template: `
    <div class="dialog-header">
      <h2 mat-dialog-title>
        {{ data.title || defaultData.title }}
      </h2>
    </div>
    <button mat-icon-button class="close-button" (click)="onCancelClicked()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-dialog-content>
      <div
        class="message"
        [innerHTML]="data.message || defaultData.message"></div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <a class="cancel-button" (click)="onCancelClicked()" cdkFocusInitial>
        <span class="label">
          {{ data.cancelLabel || defaultData.cancelLabel }}
        </span>
      </a>
      <button
        mat-flat-button
        class="confirm-button"
        (click)="onConfirmClicked()">
        {{ data.confirmLabel || defaultData.confirmLabel }}
      </button>
    </mat-dialog-actions>
  `,
})
export class ConfirmDialogComponent {
  protected defaultData = CONFIRM_DIALOG_DEFAULT_DATA;

  onConfirmClicked(): void {
    this.dialogRef.close(true);
  }

  onCancelClicked(): void {
    this.dialogRef.close();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmDialogData,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {}
}
